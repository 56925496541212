.under-construction-screen {
  display: grid;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  position:relative;

  overflow: hidden;
  background: var(--color-primary);

  grid-area: under-construction-screen;
  grid-template-areas:
    "card img";

  grid-template-columns: minmax(542px, 40%) 60%;
}

.under-construction-screen--mobile {
  grid-template-columns: 1fr;
}

.under-construction-screen > section {
  width: 100%;
  height: 100%;
  max-height: 675px;

  grid-area: card;

  /* padding: 7% 20%; */
}

.under-construction-screen__card {
  display: flex;

  height: 100%;
  min-height: 500px;
  width: 100%;
  max-width: 400px;
  min-width: 200px;

  margin: auto;

  border-radius: 50px;
  background: #62b765;
  box-shadow:  20px 20px 60px #539c56,
               -20px -20px 60px #71d274;
}

.under-construction-screen__grid {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  min-width: 320px;
  max-width: 460px;
  padding: 44px;
  margin: 0 auto;
}

.under-construction-screen__grid__header {
  padding-top: 24px;
  font-size: 28px;
  text-align: center;
}

.under-construction-screen__grid__info {
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}

.under-construction-screen__grid__info__link {
  color: #767575;
  font-style: italic;
  text-decoration: none;
}

.under-construction-screen__grid__tooltip {
  margin-top: auto;
  font-size: 14px;
  font-style: italic;
  color: var(--color-contrast);
}

.under-construction-screen__grid > :last-child {
  margin-top: 4px;
}

.under-construction-screen__grid__wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.under-construction-screen__grid__wrapper__label {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 10px;
  color: #94ea94;
  font-size: 14px;

  transition: font .1s ease,top .1s ease,transform .1s ease,-webkit-transform .1s ease,-moz-transform .1s ease,-o-transform .1s ease;
  transform: translateY(0%);
}

.under-construction-screen__grid__wrapper__label--has-text {
  transform: translateY(-150%);
  font-size: 11px;
}

.under-construction-screen__grid__wrapper:focus-within > label {
  transition: font .1s ease,top .1s ease,transform .1s ease,-webkit-transform .1s ease,-moz-transform .1s ease,-o-transform .1s ease;
  transform: translateY(-150%);
  font-size: 11px;
}

.under-construction-screen__grid__wrapper__input {
  height: 48px;
  width: 100%;
  padding: 10px 10px 0;
  border: 1px solid var(--color-primary-dark);
  border-bottom: 2px solid var(--color-primary-light);
  border-radius: 4px;


  background: #2f8639;
  font-size: 16px;
}

.under-construction-screen__grid__wrapper__input:focus {
  outline: none;
}

.under-construction-screen__grid__wrapper__btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: -26px;
  margin-left: 2px;

  background-color: var(--color-primary-dark);

  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: silver;

  cursor: pointer;
}

.under-construction-screen__grid__wrapper__btn:hover > svg > path {
  fill: blue;
}

.under-construction-screen__grid__wrapper__btn:active {
  background-color: var(--color-primary-light);
}

.under-construction-screen__grid__wrapper__btn > svg > path {
  fill: var(--color-contrast);
}

.under-construction-screen__img {
  grid-area: img;
  width:100%;
  height: 100%;
  background-image: url("https://images.unsplash.com/photo-1546164196-2e0f9b46c50d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTIxfHxwbGFuZXQlMjBlYXJ0aHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60");
  opacity: .7;
  border: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* background: rgba(0,0,0,.5); */

.search-form {
  display: inline-flex;
  width: fit-content;
  min-width: 250px;

  margin: 0 auto;

  background-color: #444444;
  border-radius: 1rem;
}

.search-input, .search-submit {
  height: 2.5rem;
  font-size: 1.5rem;
  background-color: transparent;

  border: 1px solid #DDDDDD;
}

.search-input {
  width: 100%;
  padding-left: 1em;

  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-right: none;
}

.search-input:focus {
  outline: none;
}

.search-submit {
  background-color: transparent;
  cursor: pointer;

  border-left: none;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.search-submit:focus {
  background-color: chocolate;
}

.search-submit--hover, .search-submit:hover {
  opacity: .5;
}

.home-screen {
  display: grid;
  width: 100vw;
  height: 100vh;

  grid-area: home-screen;

  grid-template-areas:
    "header header header"
    "left main right"
    "footer footer footer";

  grid-template-rows: 80px 1fr 40px;
  grid-template-columns: 80px 1fr 80px;
}

.home-screen > header {
  grid-area: header;

  background-color: cornsilk;
}

.home-screen > main {
  display: flex;
  padding: 0 10%;

  align-items: center;
  justify-content: center;

  grid-area: main;

  border-radius: var(--home-screen-main-border-radius);
  background-color: #1C1C1C;
}

.home-screen > footer {
  grid-area: footer;

  margin: 0 calc(2 * var(--home-screen-main-border-radius));
  margin-top: 4px;
  border-top: 1px solid lightgray;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-primary: #62b765;
  --color-primary-dark: #2f8639;
  --color-primary-light: #94ea94;
  --color-contrast: #185AF2;

  --home-screen-main-border-radius: 5%;
  --pos-left: 25%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
